var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-car-import",
      attrs: {
        title: "导入车牌",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("p", [
        _vm._v(
          "请先下载模板，在模板中添加车牌详细信息后，将编辑好的文件通过第二步上传。（若已下载过模板，可直接进行第二步上传数据）"
        ),
      ]),
      _c("ul", [
        _c("li", { staticClass: "label" }, [_vm._v("第一步")]),
        _c(
          "li",
          [
            _c(
              "el-button",
              {
                staticClass: "fill-box",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.downloadTemplate },
              },
              [_vm._v("下载模板")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "label" }, [_vm._v("第二步")]),
        _c(
          "li",
          [
            _c(
              "el-upload",
              {
                staticClass: "fill-box",
                attrs: {
                  action: "#",
                  accept: ".xlsx",
                  "show-file-list": true,
                  limit: 1,
                  "auto-upload": false,
                  "on-change": _vm.fileChange,
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "fill-box",
                    attrs: { size: "small", type: "primary" },
                  },
                  [_vm._v("上传文件")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }