<template>
  <el-dialog class="js-dialog-form js-car-import" title="导入车牌" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <p>请先下载模板，在模板中添加车牌详细信息后，将编辑好的文件通过第二步上传。（若已下载过模板，可直接进行第二步上传数据）</p>
    <ul>
      <li class="label">第一步</li>
      <li>
        <el-button class="fill-box" size="small" type="primary" @click="downloadTemplate">下载模板</el-button>
      </li>
      <li class="label">第二步</li>
      <li>
        <el-upload 
          class="fill-box" 
          action="#" 
          accept=".xlsx"
          :show-file-list="true" 
          :limit="1" 
          :auto-upload="false"
          :on-change="fileChange">
          <el-button class="fill-box" size="small" type="primary">上传文件</el-button>
        </el-upload>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value','id'],
  data() {
    return {
      file: '',
      formData: new FormData()
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {},
  methods: {
    downloadTemplate(){
      window.open(`${window.location.origin}/res/lpr.xlsx`)
    },
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      if(!this.file){
        this.$message({
          type: 'error',
          message: '上传文件不能为空！',
          duration: 1000
        })
        return
      }else{
        this.uploadFile()
      }
    },
    fileChange(file){
      let type = this.$Utils.getFileType(file.name)
      if(type !== 'xlsx'){
        this.$message({
          type: 'error',
          message: '文件格式有误，请上传.xlsx文件！',
          duration: 1000
        })
        return
      }else{
        this.file = file.raw
      }
    },
    uploadFile(){
      let formData = new FormData()
      formData.append('lprLibId', this.id)
      formData.append('file', this.file)
      let url = 'api/csp/lpr/v1/lpr/batchImport'
      let xhr = new XMLHttpRequest()
      xhr.open('POST', url, true)
      xhr.setRequestHeader('x-token', localStorage.eleToken)
      xhr.setRequestHeader('x-dev-type', 'web')
      xhr.setRequestHeader('x-user-id', localStorage.userId)
      xhr.send(formData)
      xhr.onload = (e) => {
        console.log('上传成功', xhr.status, xhr.statusText)
        this.$emit('uploadSuccess')
      }
       xhr.onerror = (e) => {
        console.log('上传出错', xhr.status, xhr.statusText)
      }
    }
  }
}
</script>

<style lang="scss">
.js-car-import{
  .fill-box{
    width: 100%;
    .el-upload{
      width: 100%;
    }
  }
}
</style>